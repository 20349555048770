import React, { useEffect, useState } from 'react';
import XMLViewer from 'react-xml-viewer'

const SitemapGenerator = () => {
	const [xmlSitemap, setXmlSitemap] = useState('');

	const url = process.env.REACT_APP_API_URL;

	const fetchDynamicUrls = async () => {
		try {
			const response = await fetch(`${url}/newsfeeds/all`);
			const data = await response.json();
			return data.newsfeeds?.data || [];
		} catch (error) {
			console.error("Error fetching dynamic URLs:", error);
			return [];
		}
	};

	const generateSitemap = async () => {
		const staticUrls = [
			{ loc: 'https://ecoil.in/', lastmod: new Date().toISOString(), changefreq: 'daily', priority: 1.0 },
			{ loc: 'https://ecoil.in/about-us', lastmod: new Date().toISOString(), changefreq: 'monthly', priority: 0.8 }
		];

		const dynamicUrls = await fetchDynamicUrls();
		const dynamicUrlsNew = dynamicUrls.map(url => ({
			loc: `https://ecoil.in/${url.slug}`,
			lastmod: new Date().toISOString(),
			changefreq: 'daily',
			priority: 1.0
		}));

		const allUrls = [...staticUrls, ...dynamicUrlsNew];

		let sitemapXml = `<?xml version="1.0" encoding="UTF-8"?>\n`;
		sitemapXml += `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

		allUrls.forEach((url) => {
			sitemapXml += `<url>\n`;
			sitemapXml += `  <loc>${url.loc}</loc>\n`;
			sitemapXml += `  <lastmod>${url.lastmod}</lastmod>\n`;
			sitemapXml += `  <priority>${url.priority}</priority>\n`;
			sitemapXml += `</url>\n`;
		});

		sitemapXml += `</urlset>\n`;

		setXmlSitemap(sitemapXml);
		
		setTimeout(() => {
			navigateToXML(sitemapXml);
		}, 100);
	};

	useEffect(() => {
		generateSitemap();
	}, []);

	const navigateToXML = (myXml) => {
		const blob = new Blob([myXml], { type: 'application/xml' });
		const url = URL.createObjectURL(blob);
		window.location.href = url;
		URL.revokeObjectURL(url);
	};

	return (
		<div>
			
		</div>
	);
};

export default SitemapGenerator;
