import React from "react"
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";

import thankYouImage from "../Images/thank-you.jpg"

function Thankyou() {
	return (
		<>
			<Navbar />
			<div>
				<img src={thankYouImage} className="w-100 h-auto" alt="Thank You"  />
			</div>
			<Footer />
		</>
	);
}

export default Thankyou